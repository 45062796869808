<template>
  <div class="page">
    <div class="w-90 h-full md:pb-44">
      <div class="relative z-10 space-y-12 mt-10">
        <div class="media-list">
          <div v-if="page == 1 && isLoadingPage" class="media-loader-wrap">
            <ProgressSpinner/>
          </div>

          <div v-else class="media-grid-wrapper">
            <div class="media-grid grid gap-4 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-2">
              <router-link :to="`/watch/${item.anime_id}/${item.id}`" v-for="item in mediaList" :key="item.id"
                           class="media-item">
                <figure class="effect-sadie">
                  <img
                      :src="Poster"
                      v-lazy:background-image="imageUrl(item.image, true)"
                      class="poster rounded-md"
                  />
                  <figcaption>
                    <h2 class="line-clamp-2">{{ item.title }}</h2>
                    <p class="desc line-clamp-4" v-html="item.description"></p>
                  </figcaption>
                </figure>
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="hasMore && !isLoadingPage" class="pagination">
          <ProgressSpinner v-if="isLoadingMore" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
          <Button v-else type="button" @click="getEpisodes">
            <span>Цааш</span>
            <i class="pi pi-angle-double-down"></i>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Poster from "../../assets/poster.png";
import {imageUrl} from "../../utils";
import {setModalActive} from "../../store";
import useDevice from "../../hooks/useDevice";

export default {
  setup() {
    const {isDesktop} = useDevice();
    return {isDesktop};
  },

  data() {
    return {
      isLoadingPage: true,
      isLoadingMore: false,
      cat: null,
      hasMore: false,
      page: 1,
      Poster
    }
  },

  computed: {
    mediaList() {
      return this.$store.state['latestEpisodes'];
    }
  },

  created() {
    this.getEpisodes();
  },

  methods: {
    imageUrl,
    handleClick(animeID) {
      this.$router.replace({query: {media: animeID}});
      setModalActive(true);
    },

    getEpisodes() {
      this.isLoadingMore = true;
      console.log(this.page);

      this.$store.dispatch('getLatestEpisodes', {vm: this, props: {m: this.$route.params.m}});
    }
  }
};
</script>